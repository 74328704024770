<template>
  <div
    class="task"
    :class="{
      'is-mandatory': data.mandatory,
      'is-done': isDone,
      'is-past': isPast,
    }"
  >
    <div class="task-inner">
      <div class="task__picto-outer">
        <img class="task__picto" :src="data.icon" />
      </div>
      <div class="task__content">
        <h4 class="task__content__title">{{ data.title }}</h4>
        <a
          v-if="data.link?.url"
          class="task__content__link"
          :href="data.link.url"
          :target="data.link.target"
        >
          {{ data.link.title }}
        </a>
      </div>
    </div>
    <div class="task__mandatory">
      Obligatoire
    </div>
    <div class="task__done" @click.prevent="toggle">
      <div class="task__done__check">
        <SvgSprite symbol="icons-check" size="14 11" />
      </div>
    </div>
    <div class="task__time">
      <TimeChecker
        class="task__time__label"
        :data="{ date: data.deadline * 1000 }"
        type="task"
        v-if="data.deadline"
      />
      <div class="task__time__medal" v-if="data.givePoints">
        <SvgSprite class="icon-arrow" :symbol="`ui-badge`" size="16 16" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, ref } from 'vue'
import moment from 'moment'
import TimeChecker from '@/components/TimeChecker.vue'
import { done } from '@/guides/endpoints/todo'

export default defineComponent({
  name: 'Task',
  components: {
    TimeChecker,
  },
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup(props) {
    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    const isDone = ref(props.data.done)
    const toggle = () => {
      if (isDone.value) {
        done(props.data.id)
        isDone.value = false
        emitter.emit('updatetodo')
        console.log('undone', props.data.id)
      } else {
        done(props.data.id)
        isDone.value = true
        emitter.emit('updatetodo')
        console.log('done', props.data.id)
      }
    }

    const isPast = computed(() => {
      if (!props.data.deadline) {
        return false
      }

      const day = moment(props.data.deadline * 1000).startOf('day')
      const today = moment().startOf('day')

      return day.isBefore(today)
    })

    return {
      toggle,
      isPast,
      isDone,
    }
  },
})
</script>

<style lang="scss" scoped>
.task {
  min-height: 10.7rem;
  position: relative;
  // padding: $spacing * 1.25 $spacing;
  background-color: $c-white;
  border: 1px solid $athens-gray;

  &.is-past {
    opacity: 0.3;
  }
}

.task-inner {
  display: flex;
  height: 100%;
}

.task__picto-outer {
  display: flex;
  position: relative;
  width: 10rem;
  background-color: $c-white;
  justify-content: center;
  align-items: center;
  min-width: 100px;

  // &::before {
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   padding-top: (1 / 1) * 100%;
  // }
}

.task__picto {
  width: 60%;
  height: auto;
}

.task__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin: $spacing 5rem $spacing $spacing;
  // margin-left: $spacing * 2;
}

.task__content__title {
  font-size: 1.4rem;
  max-width: 160px;
}

.task__content__link {
  margin-top: $spacing / 2;
  color: $tangerine;
  font-size: 1.2rem;
  line-height: 1.3em;
  text-decoration: underline;
  padding-right: 40px;

  &:hover {
    opacity: 0.6;
  }
}

.task__done {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;

  &:hover::before {
    opacity: 0.8;
  }

  &::before {
    @include center-xy;

    content: '';
    width: 24px;
    height: 24px;
    border: 1px solid #99b3c9;
    border-radius: 50%;
  }
}

.task__done__check {
  @include center-xy;

  width: 24px;
  height: 24px;
  display: none;
  background-color: $la-rioja;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }

  .task.is-done & {
    display: flex;
  }
}

.task__mandatory {
  position: absolute;
  top: -5px;
  left: 10px;
  width: 80px;
  display: none;
  padding: 2px 4px;
  color: $c-white;
  background-color: $tangerine;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;

  .task.is-mandatory & {
    display: block;
  }
}

.task__time {
  position: absolute;
  top: 0.7rem;
  right: 0.9rem;
  display: flex;
  align-items: center;
  height: 1.6rem;
}

.task__time__label {
  color: $hippie-blue;
  font-size: 1rem;
}

.task__time__medal {
  position: relative;
  width: 1.6rem;
  height: 1.6rem;

  svg {
    @include center-xy;
  }
}
</style>
