
import { computed, defineComponent, getCurrentInstance, ref } from 'vue'
import moment from 'moment'
import TimeChecker from '@/components/TimeChecker.vue'
import { done } from '@/guides/endpoints/todo'

export default defineComponent({
  name: 'Task',
  components: {
    TimeChecker,
  },
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup(props) {
    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    const isDone = ref(props.data.done)
    const toggle = () => {
      if (isDone.value) {
        done(props.data.id)
        isDone.value = false
        emitter.emit('updatetodo')
        console.log('undone', props.data.id)
      } else {
        done(props.data.id)
        isDone.value = true
        emitter.emit('updatetodo')
        console.log('done', props.data.id)
      }
    }

    const isPast = computed(() => {
      if (!props.data.deadline) {
        return false
      }

      const day = moment(props.data.deadline * 1000).startOf('day')
      const today = moment().startOf('day')

      return day.isBefore(today)
    })

    return {
      toggle,
      isPast,
      isDone,
    }
  },
})
