import api from '@/guides/api'

export const list = (month?: string) => {
  return api.get('/todos', {
    params: {
      month,
    },
  })
}

export const done = (id: number) => {
  return api.put(`/todos/${id}`)
}

export const undone = (id: number) => {
  return api.delete(`/todos/${id}`)
}
